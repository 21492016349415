import './styles/main.scss';

import './scripts/navigation.js';
import './scripts/opening-hours.js';
import './scripts/cookienotify.js';

// Cookie notification 
(function($) {
$(".site").cookieNotify({
	cookieText: 'Vi använder <a href="http://cookielagen.se/cookies/" title="Om cookies">cookies</a> för att ge dig en bättre upplevelse av vår webb. Genom att använda vår hemsida godkänner du detta.',
	cookieButtonText: 'Jag förstår'
});

var iOS5 = navigator.userAgent.match(/OS 5_/i) != null;

if (iOS5) {
	$("a.read-more").css('background-color','red');
	$(".app-cookie").css('background','red');
	$(".app-cookie").css('transform','none');
}
})(jQuery);
