/*
 * jQuery Notify v1.0
 * Copyright 2015 Internetavdelningen Gota Media AB
 * Contributing Author: Veronica Axelsson
 */
;

import './vendor/jquery.cookie';

jQuery(function ($) {
	$.fn.cookieNotify = function( options ) {

		var settings = $.extend({
    	//These are the defaults.
      cookieText: 'We use cookies on this website.',
			cookieButtonText: 'Approval'
      }, options );


	    $( this ).prepend( "<div class='cookie-box'>"+settings.cookieText+" <button class='app-cookie'>"+settings.cookieButtonText+"</button></div>" );

	    if (typeof $.cookie('ApprovalCookie') === "undefined") {
	        $( ".cookie-box" ).addClass( "open" );
	    }

	    $( ".app-cookie" ).click( function(){
	        $.cookie('ApprovalCookie', 'approved', { expires: 365, path: '/' });
	        $( ".cookie-box" ).removeClass( "open" );
	    });
	};

}( jQuery ));
