(function($) {
	$(window).load(function() {
		$.ajax({
			type: 'POST',
			url: '/wp-admin/admin-ajax.php',
			dataType: 'html',
			data: {
				action: 'opening_hours',
			},
			success: function(res) {
				let times = JSON.parse(res);
				const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
				const weekdays_swe = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'];

				$('#opening-hours .button p').html(`<span>Öppet idag:</span> ${times.today}`);


				weekdays.forEach((day, index) => {
					$('#opening-hours .times').append(`<li><span>${weekdays_swe[index]}</span> ${times[day]}</li>`);
				});

				$('#opening-hours .button').on('click', (e) => {
					e.preventDefault();
					$('#opening-hours ul').toggleClass('open');
					$('#opening-hours .icon-chevron').toggleClass('flipped');
				});
			}
		})
	})
})(jQuery);